<template>
  <div class="investors">
    <div class="main-bg">
      <img src="./../assets/investors_main_bg.jpg" alt="ScoreOne" />
    </div>
    <div class="main-content">
      <h2>{{$t('Investor.title')}}</h2>
      <p>{{$t('Investor.text1')}}</p>
      <p>{{$t('Investor.text2')}}</p>
     <!-- <p>{{$t('Investor.text3')}}</p>-->
      <div class="data-content">
        <div class="data-list-item">
          <span>1.9 <i>{{$t('Investor.millions')}}</i></span>
          <h4>{{$t('Investor.data_title1')}}</h4>
        </div>
        <div class="data-list-item">
          <span><i>{{$t('Investor.more_than')}}</i> 671000</span>
          <h4>{{$t('Investor.data_title2')}}</h4>
        </div>
        <div class="data-list-item">
          <span><i>{{$t('Investor.USD')}}</i> 127 <i>{{$t('Investor.millions')}}</i></span>
          <h4>{{$t('Investor.data_title3')}}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Investors'
}
</script>

<style lang="scss">
.investors {
  width: 100%;
  .main-bg {
    width: 100%;
    min-width: 1200px;
    img {
      width: 100%;
    }
  }
  .main-content {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 100px;
    h2 {
      font: normal bold 45px Arial;
      color: #2A2A2A;
      padding: 80px 0 35px;
    }
    p {
      font: normal 500 22px Arial;
      color: #656565;
      margin-bottom: 20px;
    }
    .data-content {
      display: flex;
      justify-content: space-around;
      margin-top: 55px;
      .data-list-item {
        text-align: center;
        span {
          font: normal bold 70px Arial;
          color: #5490EA;
          i {
            font-size: 32px;
          }
        }
        h4 {
          font: normal 500 18px Arial;
          color: #656565;
          padding-top: 10px;
        }
      }
    }
  }
}
</style>
